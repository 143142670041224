export default class RemoteModal {
  constructor(triggers) {
    this.triggers = triggers;
  }

  init() {
    this.triggers.on('click', (event) => {
      event.preventDefault();
      const $currentTrigger = $(event.currentTarget);
      $currentTrigger.addClass('loading');
      const endpoint = $currentTrigger.data('modal-source');

      $.ajax({
        method: 'GET',
        url: endpoint
      }).done((response) => {
        $currentTrigger.removeClass('loading');
        this.showModal(response);
      }).fail((_response) => {
        $currentTrigger.removeClass('loading');
        this.showErrorMessage();
      });
    });
  }

  showModal(response) {
    const $modal = $('[data-modal-container]');

    $modal.addClass('ui modal');
    $modal.html(response);
    $modal.modal('show');
    return $(document).trigger('initialize', $modal);
  }

  showErrorMessage() {
    $('body').toast({
      message: 'Failed to fetch modal content',
      class: 'error',
      position: 'top center',
      displayTime: 0
    });
  }
}
